export const initialState = {
    user: null,
    myurls: []
};

export const actionTypes = {
    SET_USER: "SET_USER",
    SET_URLS: "SET_URLS",
    SET_SINGLE_URL: "SET_SINGLE_URL"
};

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case actionTypes.SET_URLS:
            return {
                ...state,
                myurls: action.allUrls,
            };
        case actionTypes.SET_SINGLE_URL:
            state.myurls.unshift(action.newurl);
            return {
                ...state,
                myurls: state.myurls,
            };
        default:
            return state;
    }
};

export default reducer;