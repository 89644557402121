import React, { useState } from 'react';
import db from '../firebase.js';
import { useStateValue } from '../StateProvider.js';
import { actionTypes } from '../Reducer.js';
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert';
import { Twitter, Facebook, Linkedin, Mail, Whatsapp, Telegram, Reddit, Pinterest } from 'react-social-sharing';
import { toast } from 'react-toastify';
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from 'qrcode.react';
import { rollIn, flipInX } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import * as firebase from 'firebase/app';

const action = {
    QRCODE: 'qrcode',
    EDITURL: 'editurl',
    DETAILS: 'details'
}

const handleOriginalUrlChange = (e, changeValue, shortUrl, dispatch, myurls, setSwitchScreen, originalUrl) => {
    e.preventDefault();
    var updatedOriginalUrl = changeValue;
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var urlvalidationregex = new RegExp(expression);
    if (changeValue.search(/^http[s]?:\/\//) === -1) {
        updatedOriginalUrl = 'http://' + changeValue;
    }
    if (updatedOriginalUrl.match(urlvalidationregex) == null) {
        toast.error("Please enter a correct URL!");
    } else if (updatedOriginalUrl === originalUrl) {
        toast.error("Please enter a new URL if you want to change!");
    } else {
        confirmAlert({
            title: 'Confirm to Change',
            message: 'Are you sure to do this? The action can\'t be undone',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const docLoc = db.collection('urls').where("shortUrl", "==", shortUrl);
                        await docLoc.get().then(ele => {
                            db.collection('urls').doc(ele.docs[0].id).update({ originalUrl: updatedOriginalUrl });
                            dispatch({
                                type: actionTypes.SET_URLS,
                                allUrls: myurls.map(item => {
                                    if (item.shortUrl === shortUrl) {
                                        return { ...item, originalUrl: updatedOriginalUrl, count: ele.docs[0].data().count }
                                    }
                                    return item;
                                })
                            });
                            toast.success("Changed Original URL Successfully!");
                            setSwitchScreen(action.DETAILS);
                        });
                    }
                },
                {
                    label: 'No',
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    }
};

const selectScreen = (switchScreen, props, changeValue, setChangeValue, dispatch, myurls, setSwitchScreen) => {
    if (switchScreen === action.EDITURL) {
        return (
            <StyleRoot>
                <div className="flex-grow-0" style={{
                    animation: 'x 1s',
                    animationName: Radium.keyframes(flipInX, 'flipInX')
                }} >
                    <form onSubmit={(e) => handleOriginalUrlChange(e, changeValue, props.element['shortUrl'], dispatch, myurls, setSwitchScreen, props.element['originalUrl'])}>
                        <h1 className="">{props.element['shortUrl']}</h1>
                        <h2 className="w-full bg-gray-800 text-white title-font font-medium p-2">Edit Original URL</h2>
                        <input className="appearance-none block w-full bg-white-800 text-black border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:border-red-500" type="text" value={changeValue} onChange={(e) => setChangeValue(e.target.value)} />
                        <button className="w-full text-white bg-red-500 border-0 py-2 px-6 my-5 focus:outline-none hover:bg-red-600 rounded text-sm md:text-lg" type="submit" >Change</button>
                    </form>
                </div>
            </StyleRoot>
        );
    } else if (switchScreen === action.QRCODE) {
        return (
            <StyleRoot>
                <div className="flex-grow-0" style={{
                    animation: 'x 1s',
                    animationName: Radium.keyframes(flipInX, 'flipInX')
                }} >
                    <QRCode id={props.element['shortUrlCode']} level={"Q"} includeMargin={true} size={200} value={props.element['shortUrl']} />
                    <button onClick={() => {
                        const canvas = document.getElementById(props.element['shortUrlCode']);
                        const pngUrl = canvas
                            .toDataURL("image/png")
                            .replace("image/png", "image/octet-stream");
                        let downloadLink = document.createElement("a");
                        downloadLink.href = pngUrl;
                        downloadLink.download = `LNQ.is-${props.element['shortUrlCode']}.png`;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    }} className="w-full text-white bg-red-500 border-0 py-2 px-6 my-5 focus:outline-none hover:bg-red-600 rounded text-sm md:text-lg">Download QR Code</button>
                </div>
            </StyleRoot>
        );
    } else {
        return (
            <StyleRoot><div className="flex-grow" style={{
                animation: 'x 1s',
                animationName: Radium.keyframes(flipInX, 'flipInX')
            }} >
                <h2 onClick={() => window.open(props.element['shortUrl'], "_blank")} className="cursor-pointer bg-gray-800 text-white title-font font-medium p-2"><span className="text-gray-600">Short URL:</span> {props.element['shortUrl']}</h2>

                <h3 className="break-all py-2"><span className="text-gray-600 ">Original URL:</span> {props.element['originalUrl'].slice(0, 50)}...</h3>


                <p className="text-gray-600">Link Opened <span className="text-gray-200">{props.element['count']}</span> Times</p>

                <p className="text-gray-200 mt-2">Share Link:</p>
                <Facebook name=" " link={props.element["shortUrl"]} />
                <Twitter name=" " link={props.element["shortUrl"]} />
                <Reddit name=" " link={props.element["shortUrl"]} />
                <Pinterest name=" " link={props.element["shortUrl"]} />
                <Whatsapp name=" " link={props.element["shortUrl"]} />
                <Mail name=" " link={props.element["shortUrl"]} />
                <Linkedin name=" " link={props.element["shortUrl"]} />
                <Telegram name=" " link={props.element["shortUrl"]} />

                <p>Created at <Moment format="DD/MM/YYYY hh:mm A" date={new Date(props.element['createdAt'])} /></p>
            </div> </StyleRoot>
        );
    }
};

function HistoryCard(props) {

    const [{ myurls, user }, dispatch] = useStateValue();
    const [switchScreen, setSwitchScreen] = useState(action.DETAILS);
    const [changeValue, setChangeValue] = useState(props.element['originalUrl']);

    return (
        <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
            <div className=" h-full flex items-center border-gray-800 border p-4 rounded-lg">
                <div className="flex-1 mr-2">
                    {switchScreen === action.DETAILS ? <CopyToClipboard text={props.element['shortUrl']}>
                        <StyleRoot><button style={{
                            animation: 'x 1s',
                            animationName: Radium.keyframes(rollIn, 'rollIn')
                        }} onClick={() => toast.success("Copied!")} className="cursor-pointer w-16 h-16 bg-gray-100 object-fill object-center flex-shrink-0 rounded-full m-2"><i className="fa fa-clone fa-lg" aria-hidden="true"></i><small> COPY</small></button></StyleRoot>
                    </CopyToClipboard> : null}
                    {user && (switchScreen === action.DETAILS) ? <StyleRoot>
                        <button style={{
                            animation: 'x 1s',
                            animationName: Radium.keyframes(rollIn, 'rollIn')
                        }} onClick={() => {
                            confirmAlert({
                                title: 'Confirm to Delete',
                                message: 'Are you sure to do this?',
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: async () => {
                                            const docLoc = db.collection('urls').where("shortUrl", "==", props.element['shortUrl']);
                                            await docLoc.get().then(ele => {
                                                db.collection('urls').doc(ele.docs[0].id).update({ createdBy: "anonymous" });
                                                db.collection('users').doc(props.element['createdBy']).update({ countGeneratedLinks: firebase.firestore.FieldValue.increment(-1) });
                                                dispatch({
                                                    type: actionTypes.SET_URLS,
                                                    allUrls: myurls.filter(item => item.shortUrl !== props.element['shortUrl'])
                                                });
                                            });
                                        }
                                    },
                                    {
                                        label: 'No',
                                    }
                                ],
                                closeOnEscape: true,
                                closeOnClickOutside: true,
                            });
                        }} className="cursor-pointer w-16 h-16 bg-gray-100 object-fill object-center flex-shrink-0 rounded-full m-2"><i className="fa fa-trash fa-lg" aria-hidden="true"></i><small> DEL</small></button></StyleRoot> : null}
                    {switchScreen === action.DETAILS ? <StyleRoot><button style={{
                        animation: 'x 1s',
                        animationName: Radium.keyframes(rollIn, 'rollIn')
                    }} onClick={() => setSwitchScreen(action.QRCODE)} className="cursor-pointer w-16 h-16 bg-gray-100 object-fill object-center flex-shrink-0 rounded-full m-2"><i className="fa fa-qrcode fa-lg" aria-hidden="true"></i><small> QR</small></button></StyleRoot> : null}
                    {switchScreen === action.DETAILS ? <StyleRoot><button style={{
                        animation: 'x 1s',
                        animationName: Radium.keyframes(rollIn, 'rollIn')
                    }} onClick={() => setSwitchScreen(action.EDITURL)} alt="copy" className="cursor-pointer w-16 h-16 bg-gray-100 object-fill object-center flex-shrink-0 rounded-full m-2"><i className="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i><small> EDIT</small></button></StyleRoot> : null}
                    {switchScreen !== action.DETAILS && <StyleRoot> <button onClick={() => setSwitchScreen(action.DETAILS)} style={{
                        animation: 'x 1s',
                        animationName: Radium.keyframes(rollIn, 'rollIn')
                    }} alt="copy" className="cursor-pointer w-16 h-16 bg-gray-100 object-fill object-center flex-shrink-0 rounded-full m-2"><i className="fa fa-chevron-left fa-lg" aria-hidden="true"></i><small> BACK</small></button>
                    </StyleRoot>}
                </div>

                {selectScreen(switchScreen, props, changeValue, setChangeValue, dispatch, myurls, setSwitchScreen)}
            </div>
        </div>
    );
}

export default HistoryCard;
