import React, { useState, useEffect } from 'react';
import db, { auth } from '../firebase.js';
import { useStateValue } from '../StateProvider.js';
import { ToastContainer } from 'react-toastify';
import OutsideAlerter from './OutsideAlerter';
import * as animations from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { ShowPopup } from './ShowPopup.js';


export function Header(props) {
  const [{ user },] = useStateValue();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [apiGranted, setApiGranted] = useState(false);
  const [showAPIPopup, setShowAPIPopup] = useState(false);

  useEffect(() => {
    user && db.collection('approvedAPIs').doc(user.uid).onSnapshot(snapshot => {
      if (snapshot.exists) {
        setApiGranted(snapshot.data().approved);
      }
    });
  }, [user]);

  return (
    <div>
      <ToastContainer position="top-right"
        // eslint-disable-next-line
        position="bottom-left"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} />
      <header className="text-gray-500 bg-gray-900 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-row items-center">
          <button onClick={() => window.location.replace("/")} className="flex title-font font-medium items-center text-white mb-4">
            <img src="/images/logo.png" className="w-16 h-16 text-white p-2 bg-red-500 rounded" alt="logo" />
            <span className="ml-3 text-xl">LNQ.is</span>
          </button>
          <nav className="ml-auto flex flex-wrap items-center text-base justify-center">

            <div className="relative inline-block text-left">
              <OutsideAlerter closeDropdown={setIsDropdownOpen}>
                <div>
                  <span className="rounded-md shadow-sm">
                    {user && <StyleRoot><button style={{
                      animation: 'x 1s',
                      animationName: Radium.keyframes(animations.rubberBand, 'rubberBand')
                    }} onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="flex flex-row items-center mx-5 p-2" id="options-menu" aria-haspopup="true" aria-expanded="true">
                      <img alt="Profile" src={user.photoURL} className="w-10 h-10 rounded-full" />
                      <p className="hidden md:block ml-2 hover:text-white">{user.displayName}</p>
                    </button></StyleRoot>}
                    {!user && <button onClick={() => setShowLogin(true)} className="inline-flex text-white bg-red-500 border-0 py-2 px-6 my-5 focus:outline-none hover:bg-red-600 rounded text-sm md:text-lg">Sign In</button>}
                  </span>
                </div>
                <div className={`${!isDropdownOpen && "transform ease-in duration-75 ease-out duration-100 opacity-0 scale-0"} origin-top-right absolute right-0 mt-3 w-56 rounded-md shadow-lg`}>
                  <div className="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <div className="py-1">
                      {user && <button onClick={() => { auth.signOut(); setIsDropdownOpen(!isDropdownOpen); }} className="w-full text-left block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i className="fa fa-sign-out mr-2" aria-hidden="true"></i>Sign Out</button>}
                      {user && <button onClick={() => { setShowAPIPopup(true) }} className="w-full text-left block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem"><i className="fa fa-plug mr-2" aria-hidden="true"></i>{apiGranted === "true" ? "Read API Docs" : "Request API Key"}</button>}
                    </div>
                  </div>
                </div>
              </OutsideAlerter>
            </div>
          </nav>
        </div>
      </header>
      {showLogin && <ShowPopup type="login" closePopup={setShowLogin}
        signInWithGoogle={props.signInWithGoogle} />}
      {showAPIPopup && <ShowPopup type="apiPopup" closePopup={setShowAPIPopup} apiData={apiGranted} />}
    </div>
  );
}
