import React, { useEffect } from 'react';
import './App.css';
import db, { auth, provider } from './firebase.js';
import { useStateValue } from './StateProvider.js';
import { actionTypes } from './Reducer.js';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from './components/Header';
import { Hero } from './components/Hero';
import { MyUrls } from './components/MyUrls';
import { Footer } from './components/Footer';
import * as firebase from 'firebase/app';


function App() {

  const getGeoInfo = () => {
    return fetch('https://ipapi.co/json/')
      .then((response) => response.json())
      .then(data => { return data.country_code })
      .catch((error) => {
        console.log(error);
      });
  };

  const [{ user }, dispatch] = useStateValue();
  const signInWithGoogle = () => {
    auth.signInWithPopup(provider)
      .then(result => {
        if (result.additionalUserInfo.isNewUser === true) {
          var country_code = null;
          getGeoInfo().then(cCode => country_code = cCode).then(() => {
            db.collection('analytics').doc('countryCodes').get().then(data => {
              var tempIdNum = (data.data()[country_code] || 0).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false });
              var tempId = country_code + tempIdNum;
              db.collection('analytics').doc('countryCodes').update({ [country_code]: firebase.firestore.FieldValue.increment(1) });
              db.collection('users').doc(result.user.uid).set({
                countGeneratedLinks: 0,
                creationDate: firebase.firestore.FieldValue.serverTimestamp(),
                disabled: false,
                email: result.user.email,
                id: tempId,
                isAdmin: false,
                name: result.user.displayName,
                photoURL: result.user.photoURL,
                plan: "basic"
              });
            });
          });
        }
        dispatch({
          type: actionTypes.SET_USER,
          user: result.user,
        });
      })
      .catch(error => toast.error(error.message));
  };

  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth == null) {
        dispatch({
          type: actionTypes.SET_URLS,
          allUrls: [],
        });
      }
      dispatch({
        type: actionTypes.SET_USER,
        user: userAuth,
      });
    });
    // eslint-disable-next-line
  }, [user]);


  return (
    <div className="App">
      <Header signInWithGoogle={signInWithGoogle} />
      <Hero />
      <MyUrls />
      <Footer />
    </div>
  );
}

export default App;
