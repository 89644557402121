import React, { useState } from 'react';
import { useStateValue } from '../StateProvider.js';
import { actionTypes } from '../Reducer.js';
import { toast } from 'react-toastify';
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as animations from 'react-animations';
import Radium, { StyleRoot } from 'radium';

export function Hero() {
  const [{ user }, dispatch] = useStateValue();
  const [originalUrl, setOriginalUrl] = useState('');
  const [shortUrlCode, setShortUrlCode] = useState('');
  const [successShortUrl, setSuccessShortUrl] = useState();

  const handleSubmit = (e) => {
    var createdBy;
    var reqBody;
    var updatedOriginalUrl;
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var urlvalidationregex = new RegExp(expression);
    if (originalUrl.trim().length > 0) {
      if (originalUrl.search(/^http[s]?:\/\//) === -1) {
        updatedOriginalUrl = 'http://' + originalUrl;
      } else {
        updatedOriginalUrl = originalUrl;
      }
      if (updatedOriginalUrl.match(urlvalidationregex) == null) {
        toast.error("Please enter a correct URL!");
      } else {
        if (user) {
          createdBy = user.uid;
          reqBody = { originalUrl: updatedOriginalUrl, APIKey: createdBy, shortUrlCode: shortUrlCode };
        } else {
          createdBy = "anonymous";
          reqBody = { originalUrl: updatedOriginalUrl, APIKey: createdBy, };
        }
        fetch('/api/new', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(reqBody)
        }).then(response => response.json())
          .then(data => {
            toast.success(data['message']);
            if (data['statusCode'] === 200) {
              setSuccessShortUrl(data['shortUrl']);
              dispatch({
                type: actionTypes.SET_SINGLE_URL,
                newurl: data,
              });
            }
          });
        setOriginalUrl('');
        setShortUrlCode('');
      }
    } else {
      toast.error("Please enter a correct URL!");
    }
    e.preventDefault();
  };

  return (
    <div>
      <section className="text-gray-500 bg-gray-900 body-font">
        <StyleRoot>
          <div style={{
            animation: 'x 0.5s',
            animationName: Radium.keyframes(animations.zoomIn, 'zoomIn')
          }} className="container mx-auto flex px-5 py-2 md:py-16 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/3 w-4/6 mb-10 md:mb-0">
              <img className="object-cover object-center rounded" alt="hero" src="/images/heroimg.png" />
            </div>
            <div className="lg:flex-grow md:w-2/3 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">Quickly Shorten Your URLs</h1>
              <p className="hidden md:block mb-8 leading-relaxed text-center md:text-justify">Who likes to remember long long urls, when you can generate your own short url with just one click. Just enter your URL and click generate, there you go. It's that easy</p>
              {!user && <p className="mb-8 leading-relaxed text-white text-center md:text-justify">Sign in to get your custom short url and also see history and analytics.</p>}
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className={`w-full ${user ? " md:w-1/2 " : ""} px-3 mb-6 md:mb-0`}>
                    <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" htmlFor="originalNameInput">Ex: facebook.com</label>
                    <input value={originalUrl} onChange={(e) => {
                      setOriginalUrl(e.target.value);
                    }} className="appearance-none block w-full bg-gray-800 text-white border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:border-red-500" id="originalNameInput" type="text" placeholder="Enter URL" />
                  </div>
                  {user && <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2" htmlFor="shortUrlDownload">(Optional) Ex: fb</label>
                    <input value={shortUrlCode} onChange={(e) => {
                      setShortUrlCode(e.target.value);
                    }} className="appearance-none block w-full bg-gray-800 text-white border border-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:border-red-500" id="shortUrlDownload" type="text" placeholder="Short URL Code" />
                  </div>}
                </div>
                {successShortUrl != null && <StyleRoot><div style={{
                  animation: 'x 1s',
                  animationName: Radium.keyframes(animations.bounceIn, 'bounceIn')
                }} className="text-center py-4 ">
                  <div className="p-2 w-full bg-green-700 items-center text-indigo-100 leading-none lg:rounded flex lg:inline-flex" role="alert">
                    <CopyToClipboard text={successShortUrl}>
                      <span onClick={() => toast.success("Copied!")} className="cursor-pointer flex rounded-full bg-green-500 uppercase px-2 py-1 text-xs font-bold mr-3">Copy</span>
                    </CopyToClipboard>
                    <span className="font-semibold mr-2 text-left flex-auto">{successShortUrl}</span>
                    <svg onClick={() => setSuccessShortUrl(null)} className="cursor-pointer fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                  </div>
                </div></StyleRoot>}
                <div className="md:flex md:items-center">
                  <div className="md:w-2/3">
                    <button onClick={handleSubmit} className="inline-flex text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">Generate</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </StyleRoot>
      </section>
    </div>

  );
}
