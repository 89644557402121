import React, { useState, useEffect } from 'react';
import db from '../firebase.js';
import { useStateValue } from '../StateProvider.js';
import { actionTypes } from '../Reducer.js';
import HistoryCard from './HistoryCard';

export function MyUrls(props) {

  const [{ myurls, user }, dispatch] = useStateValue();
  const [isExpanded, updateIsExpanded] = useState(false);
  const itemsToShow = 3;

  useEffect(() => {

    if (user) {
      var userAllUrls = [];
      const docLoc = db.collection('urls').where("createdBy", "==", user.uid);
      docLoc.get().then(matchFound => {
        matchFound.docs.forEach(url => {
          userAllUrls.push(url.data());
        });
        userAllUrls.sort(function (x, y) {
          return new Date(y.createdAt) - new Date(x.createdAt);
        });
        if (userAllUrls.length > 0) {
          dispatch({
            type: actionTypes.SET_URLS,
            allUrls: userAllUrls
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div>
      <section className="text-gray-500 bg-gray-900 body-font">
        <div className="container px-5 py-5 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Your URLs History</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Here you can see and manage all the short links you generated</p>
          </div>
          <div className="flex flex-wrap -m-2">
            {myurls.length > 0 ?

              myurls.slice(0, isExpanded ? myurls.length : Math.min(itemsToShow, myurls.length)).map(element => {
                return (
                  <HistoryCard key={element['shortUrlCode']} element={element} />
                );
              })
              :
              <div className="p-2 w-full">
                <div className="h-full flex items-center border-gray-800 border p-4 rounded-lg">
                  <img alt="team" className="w-16 h-16 bg-gray-100 object-scale-down object-center flex-shrink-0 rounded-full mr-4" src="/images/emptybox.png" />
                  <div className="flex-grow">
                    <h2 className="text-white title-font font-medium">No URLs</h2>
                    <p className="text-gray-600">You have not shortend any urls yet! Start Now</p>
                  </div>
                </div>
              </div>}

          </div>
          {
            myurls.length > itemsToShow && <div className="flex flex-row w-full mb-20">
              <div className="w-1/3"></div>
              <button className="w-1/3 mt-10 text-center text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg" onClick={() => { updateIsExpanded(!isExpanded); }}>{isExpanded ? "Show Less" : "Show More"}</button>
              <div className="w-1/3">
              </div>
            </div>
          }
        </div>
      </section>
    </div >
  );
}
