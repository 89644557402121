import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  databaseURL: "https://lnq-is-old.firebaseio.com",
  apiKey: "AIzaSyCclU3vL7C3DKvx-4wsA2XcqFPa6flUgC4",
  authDomain: "lnq-is-old.firebaseapp.com",
  projectId: "lnq-is-old",
  storageBucket: "lnq-is-old.appspot.com",
  messagingSenderId: "254690259329",
  appId: "1:254690259329:web:597a11c422cd9afd8bf880",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
const provider = new firebase.auth.GoogleAuthProvider();
export default db;
export { auth, provider, firebaseApp };
