import React, { useState } from 'react';
import db from '../firebase';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import { useStateValue } from '../StateProvider';
import { actionTypes } from '../Reducer';
import firebase from 'firebase/app';

const authOptions = {
  LOGIN: "login",
  REGISTER: "register",
  FORGOTPASSWORD: "forgotpassword"
};

const getGeoInfo = () => {
  return fetch('https://ipapi.co/json/')
    .then((response) => response.json())
    .then(data => { return data.country_code })
    .catch((error) => {
      toast.error(error.message);
    });
};

const signInWithEmail = (e, email, password, dispatch, closePopup) => {
  e.preventDefault();
  auth.signInWithEmailAndPassword(email, password).then(user => {
    dispatch({
      type: actionTypes.SET_USER,
      user: user.user,
    });
    closePopup(false);
  }).catch(err => {
    toast.error(err.message);
  })
};

const signUpWithEmailandPassword = (e, email, password, fullname, dispatch, closePopup) => {
  e.preventDefault();
  auth.createUserWithEmailAndPassword(email, password).then(user => {
    auth.currentUser.updateProfile({
      displayName: fullname,
      photoURL: "https://image.freepik.com/free-vector/profile-icon-male-avatar-hipster-man-wear-headphones_48369-8728.jpg"
    });
    var country_code = null;
    getGeoInfo().then(cCode => country_code = cCode).then(() => {
      db.collection('analytics').doc('countryCodes').get().then(data => {
        var tempIdNum = (data.data()[country_code] || 0).toLocaleString('en-US', { minimumIntegerDigits: 5, useGrouping: false });
        var tempId = country_code + tempIdNum;
        db.collection('analytics').doc('countryCodes').update({ [country_code]: firebase.firestore.FieldValue.increment(1) });
        db.collection('users').doc(user.user.uid).set({
          countGeneratedLinks: 0,
          creationDate: firebase.firestore.FieldValue.serverTimestamp(),
          disabled: false,
          email: user.user.email,
          id: tempId,
          isAdmin: false,
          name: user.user.displayName,
          photoURL: user.user.photoURL,
          plan: "basic"
        });
      });
    });
    dispatch({
      type: actionTypes.SET_USER,
      user: user.user,
    });
    closePopup(false);
  }).catch(err => {
    toast.error(err.message);
  });
};

const forgotPassword = (e, email, closePopup) => {
  e.preventDefault();
  auth.sendPasswordResetEmail(email).then(() => {
    closePopup(false);
    toast.success("Please check your email!")
  }).catch(err => toast.error(err.message));
};

const submitFeedback = (e, feebackName, feedbackemail, feedbackMessage, closePopup) => {
  e.preventDefault();
  if (feebackName.trim() !== "" && feedbackMessage.trim() !== "") {
    db.collection('feedbacks').add({
      name: feebackName,
      email: feedbackemail,
      message: feedbackMessage,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }).then(() => {
      toast.success("Feedback submitted successfully");
      db.collection('analytics').doc('stats').update({
        totalFeedbacks: firebase.firestore.FieldValue.increment(1)
      });
      closePopup(false);
    }).catch(() => toast.error("There is some error submitting, please try again!"));
  } else {
    toast.error("Please fill the details correctly");
  }
}

export function ShowPopup(props) {
  const [{ user }, dispatch] = useStateValue();
  const [feebackName, setfeebackName] = useState("");
  const [feedbackemail, setfeedbackemail] = useState("");
  const [feedbackMessage, setfeedbackMessage] = useState("");
  const [authMethod, setAuthMethod] = useState(authOptions.LOGIN);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");

  if (props.type === "about") {
    return (
      <div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      About Us<br />
                    </h3>
                    <div className="mt-2">
                      <h1>LNQ is a trouble-free tool which can give a big of a deal in terms of link tracking. This tool serves you for URL shortening which covers full analytics and archives of the stuff you shorten.
Rest assured that LNQ is a neat, fast, and superb site to help you lessen the hassle.</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button onClick={() => props.closePopup(false)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Close
          </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.type === "contact") {
    return (
      <div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <form onSubmit={e => submitFeedback(e, feebackName, feedbackemail, feedbackMessage, props.closePopup)} className="w-full max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <i className="fa fa-question-circle" aria-hidden="true"></i>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                        Help and Support<br />

                      </h3>

                    </div>
                  </div>
                  <div className="mt-2">

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-full-name">Full Name</label>
                        <input value={feebackName} onChange={e => setfeebackName(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-full-name" type="text" placeholder="Full Name" required />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">Email</label>
                        <input value={feedbackemail} onChange={e => setfeedbackemail(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email" placeholder="Email Address" required />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">Message</label>
                        <textarea value={feedbackMessage} onChange={e => setfeedbackMessage(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" type="text" placeholder="Message" required />
                        <p className="text-gray-600 text-xs italic">Please give breif description of the problem or suggestion you want to give.</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button onClick={() => props.closePopup(false)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 mx-2">Close</button>

                    <button type="submit" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 mx-2">Submit</button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.type === "login") {
    return (
      <div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;<div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

              <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
                <div className="flex items-center justify-center bg-gray-50 py-4 px-4 sm:px-6 lg:px-8">
                  <div className="max-w-md w-full">
                    <div>
                      <img className="mx-auto h-12 w-auto" src="/logo512.png" alt="Logo" />
                      <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                        {authMethod === authOptions.LOGIN ? "Sign in to your account" : authMethod === authOptions.REGISTER ? "Sign up for new account" : "Reset your password"}
                      </h2>
                    </div>
                    <form className="mt-8" onSubmit={(e) => {
                      if (authMethod === authOptions.LOGIN) {
                        signInWithEmail(e, email, password, dispatch, props.closePopup);
                      } else if (authMethod === authOptions.REGISTER) {
                        signUpWithEmailandPassword(e, email, password, fullName, dispatch, props.closePopup);
                      } else if (authMethod === authOptions.FORGOTPASSWORD) {
                        forgotPassword(e, email, props.closePopup);
                      }
                    }}>
                      <div className="rounded-md shadow-sm">
                        <div>
                          <input aria-label="Email address" type="email" value={email} onChange={e => setEmail(e.target.value)} required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Email address" />
                        </div>
                        {authMethod === authOptions.REGISTER && <div className="-mt-px">
                          <input aria-label="FullName" value={fullName} onChange={e => setFullName(e.target.value)} type="text" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Full Name" />
                        </div>}
                        {authMethod !== authOptions.FORGOTPASSWORD && <div className="-mt-px">
                          <input aria-label="Password" value={password} onChange={e => setPassword(e.target.value)} type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Password" />
                        </div>}
                      </div>

                      <div className="mt-6">
                        <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          {authMethod === authOptions.LOGIN ? "Sign in" : authMethod === authOptions.REGISTER ? "Sign up" : "Send Reset Link"}
                        </button>
                      </div>

                      <div className="mt-6 flex items-center justify-between">
                        <div className="text-sm leading-5">
                          <label className="ml-2 text-sm leading-5 text-gray-900">
                            {authMethod === authOptions.LOGIN ? "New User?" : "Have an account?"}
                          </label>
                          <button type="button" onClick={() => {
                            if (authMethod === authOptions.LOGIN) {
                              setAuthMethod(authOptions.REGISTER)
                            } else {
                              setAuthMethod(authOptions.LOGIN)
                            }

                          }} className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150 ml-1">
                            {authMethod === authOptions.LOGIN ? "Sign Up" : "Sign In"}
                          </button>
                        </div>

                        {authMethod === authOptions.LOGIN && <div className="text-sm leading-5">
                          <button type="button" onClick={() => setAuthMethod(authOptions.FORGOTPASSWORD)} className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                            Forgot Password?
                          </button>
                        </div>}
                      </div>
                    </form>

                    <hr className="block w-full my-4 border-0 border-t border-gray-300" />
                    <p className="mb-4 text-center">OR</p>
                    <div className="flex flex-wrap justify-center">
                      <div className="w-full sm:pl-2">
                        <button onClick={() => {
                          props.closePopup(false);
                          props.signInWithGoogle();
                        }}
                          className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          type="button"
                        ><i className="fa fa-google mr-2" aria-hidden="true"></i>Login with Google</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button onClick={() => props.closePopup(false)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 mx-2">Close</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props.type === "apiPopup") {
    return (
      <div>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                <div className="sm:flex sm:items-start">


                </div>

                <div className="mt-2">

                  {props.apiData === false && <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <div className="flex bg-teal-lighter max-w-sm mb-4">
                        <div className="w-auto text-grey-darker items-center p-4">
                          <span className="text-lg font-bold pb-4">You have not requested for the API yet!</span>
                          <p className="leading-tight">To use the API services, you have to request access and you will be able to use once admins accepts the request</p>
                          <button onClick={() => {
                            db.collection('approvedAPIs').doc(user.uid).set({
                              approved: "pending",
                              email: user.email,
                              name: user.displayName
                            });
                          }} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4  my-3 rounded focus:outline-none focus:shadow-outline" >Request</button>
                        </div>
                      </div>
                    </div>
                  </div>}
                  {props.apiData === "pending" && <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <div className="flex bg-teal-lighter max-w-sm mb-4">
                        <div className="w-auto text-grey-darker items-center p-4">
                          <span className="text-lg font-bold pb-4">Thanks for showing your interest!</span>
                          <p className="leading-tight">We have recieved your API request. Please wait until admins approve your request. Please check back later</p>
                          <p className="leading-tight">You can contact support for further information</p>
                        </div>
                      </div>
                    </div>
                  </div>}
                  {props.apiData === "true" && <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <div className="flex bg-teal-lighter max-w-sm mb-4">
                        <div className="w-auto text-grey-darker items-center p-4">
                          <span className="text-lg font-bold pb-4">API Request Approved!</span>
                          <p className="leading-tight">Here is the basic usage of the api</p>
                          <p className="leading-tight my-2 px-2 py-2 bg-gray-100">API Endpoint<br />
                          https://lnq.is/api/public/create_url
                          </p>
                          <p className="leading-tight my-2 px-2 py-2 bg-gray-100">Method: Post
                          </p>
                          <p className="leading-tight my-2 px-2 py-2 bg-gray-100">Parameters
                          </p>
                          <p className="leading-tight my-2 px-2 py-2 bg-gray-100">APIKey (required): {user.uid}
                          </p>
                          <p className="leading-tight my-2 px-2 py-2 bg-gray-100">originalUrl (required): The url you want to shorten
                          </p>
                          <p className="leading-tight my-2 px-2 py-2 bg-gray-100">shortUrlCode (optional): If provided and available your short url will be https://lnq.is/ followed by this code. Will choose a random if not provided
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button onClick={() => props.closePopup(false)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 mx-2">Close</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
